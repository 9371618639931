// Set env var instead ?
export const WebsiteName = "Observatoire des priorités du Gouvernement"
export const KioskUrl = "https://kiosque.communication.gouv.fr"

export const capitalize = function (string) {
    if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }
}
export const convertStringToLocaleNumber = function (string) {
    return parseInt(string).toLocaleString('fr-FR')
}

export const convertFloatToHuman = function (float) {
    if (Number.isInteger(parseFloat(float))) {
        return parseInt(float).toLocaleString('fr-FR')
    } else {
        return parseFloat(float).toFixed(2).toLocaleString('fr-FR')
    }
};

export const convertStringToLowerCaseWithCapitaliseFirstWord = (string) => {
    let lowerCaseStr = string.toLowerCase();

    return [lowerCaseStr.charAt(0).toUpperCase() + lowerCaseStr.slice(1)];
};

export function convertDate(date) {
    let dateTemp = date.replaceAll("-","/");
    let [year, month, day] = dateTemp.split("/");
    return `${day}-${month}-${year}`;
};

export const convertIntToHuman = function (int) {
    let res = parseFloat(int)
    if (Math.floor(res / 1000000000) >= 10) {
        res = (res / 1000000000).toFixed(0).replace('.', ',') + ' milliards'
    } else if (Math.floor(res / 1000000000) >= 2) {
        res = (res / 1000000000).toFixed(1).replace('.', ',') + ' milliards'
    } else if (Math.floor(res / 1000000000) >= 1) {
        res = (res / 1000000000).toFixed(1).replace('.', ',') + ' milliard'
    } else if (Math.floor(res / 1000000) >= 10) {
        res = (res / 1000000).toFixed(0).replace('.', ',') + ' millions'
    } else if (Math.floor(res / 1000000) >= 2) {
        res = (res / 1000000).toFixed(1).replace('.', ',') + ' millions'
    } else if (Math.floor(res / 1000000) >= 1) {
        res = (res / 1000000).toFixed(1).replace('.', ',') + ' million'
    } else if (Number.isInteger(parseFloat(res))) {
        return parseInt(res).toLocaleString('fr-FR').replace('.', ',')
    } else {
        return parseFloat(res).toFixed(2).toLocaleString('fr-FR').replace('.', ',')
    }
    return res
}

export const convertIntToHumanTable = function (int) {
    const res = parseFloat(int)
    if (isNaN(res)) {
        return int
    } else if (Number.isInteger(parseFloat(res))) {
        return parseInt(res).toLocaleString('fr-FR')
    } else {
        return parseFloat(res).toFixed(2).toLocaleString('fr-FR')
    }
}

export const convertDateToHuman = function (string) {
    const date = new Date(string)
    return date.toLocaleDateString('fr-FR')
}

export const testIfNaN = function (float) {
    return isNaN(parseFloat(float))
}

const colorsDSFR = [
    'green-bourgeon',
    'blue-ecume',
    'purple-glycine',
    'pink-macaron',
    'yellow-tournesol',
    'orange-terre-battue',
    'brown-cafe-creme',
    'beige-gris-galet',
    'green-emeraude',
    'blue-cumulus',
    'pink-tuile',
    'yellow-moutarde',
    'brown-caramel',
    'green-menthe',
    'brown-opera',
    'green-archipel',
    'green-tilleul-verveine'
]

const dep = [
    {
        value: '01',
        label: 'Ain',
        region: 'Auvergne-Rhône-Alpes',
        region_value: '84',
        classMap: 'FR-dep-01'
    },
    {
        value: '02',
        label: 'Aisne',
        region: 'Hauts-de-France',
        region_value: '32',
        classMap: 'FR-dep-02'
    },
    {
        value: '03',
        label: 'Allier',
        region: 'Auvergne-Rhône-Alpes',
        region_value: '84',
        classMap: 'FR-dep-03'
    },
    {
        value: '04',
        label: 'Alpes de Haute-Provence',
        region: "Provence-Alpes-Côte d'Azur",
        region_value: '93',
        classMap: 'FR-dep-04'
    },
    {
        value: '05',
        label: 'Hautes-Alpes',
        region: "Provence-Alpes-Côte d'Azur",
        region_value: '93',
        classMap: 'FR-dep-05'
    },
    {
        value: '06',
        label: 'Alpes-Maritimes',
        region: "Provence-Alpes-Côte d'Azur",
        region_value: '93',
        classMap: 'FR-dep-06'
    },
    {
        value: '07',
        label: 'Ardèche',
        region: 'Auvergne-Rhône-Alpes',
        region_value: '84',
        classMap: 'FR-dep-07'
    },
    {
        value: '08',
        label: 'Ardennes',
        region: 'Grand Est',
        region_value: '44',
        classMap: 'FR-dep-08'
    },
    {
        value: '09',
        label: 'Ariège',
        region: 'Occitanie',
        region_value: '76',
        classMap: 'FR-dep-09'
    },
    {
        value: '10',
        label: 'Aube',
        region: 'Grand Est',
        region_value: '44',
        classMap: 'FR-dep-10'
    },
    {
        value: '11',
        label: 'Aude',
        region: 'Occitanie',
        region_value: '76',
        classMap: 'FR-dep-11'
    },
    {
        value: '12',
        label: 'Aveyron',
        region: 'Occitanie',
        region_value: '76',
        classMap: 'FR-dep-12'
    },
    {
        value: '13',
        label: 'Bouches-du-Rhône',
        region: "Provence-Alpes-Côte d'Azur",
        region_value: '93',
        classMap: 'FR-dep-13'
    },
    {
        value: '14',
        label: 'Calvados',
        region: 'Normandie',
        region_value: '28',
        classMap: 'FR-dep-14'
    },
    {
        value: '15',
        label: 'Cantal',
        region: 'Auvergne-Rhône-Alpes',
        region_value: '84',
        classMap: 'FR-dep-15'
    },
    {
        value: '16',
        label: 'Charente',
        region: 'Nouvelle-Aquitaine',
        region_value: '75',
        classMap: 'FR-dep-16'
    },
    {
        value: '17',
        label: 'Charente-Maritime',
        region: 'Nouvelle-Aquitaine',
        region_value: '75',
        classMap: 'FR-dep-17'
    },
    {
        value: '18',
        label: 'Cher',
        region: 'Centre-Val de Loire',
        region_value: '24',
        classMap: 'FR-dep-18'
    },
    {
        value: '19',
        label: 'Corrèze',
        region: 'Nouvelle-Aquitaine',
        region_value: '75',
        classMap: 'FR-dep-19'
    },
    {
        value: '2A',
        label: 'Corse-du-Sud',
        region: 'Corse',
        region_value: '94',
        classMap: 'FR-dep-2A'
    },
    {
        value: '20',
        label: 'Corse-du-Sud',
        region: 'Corse',
        region_value: '94',
        classMap: 'FR-dep-2A'
    },
    {
        value: '2B',
        label: 'Haute-Corse',
        region: 'Corse',
        region_value: '94',
        classMap: 'FR-dep-2B'
    },
    {
        value: '21',
        label: "Côte-d'Or",
        region: 'Bourgogne-Franche-Comté',
        region_value: '27',
        classMap: 'FR-dep-21'
    },
    {
        value: '22',
        label: "Côtes d'Armor",
        region: 'Bretagne',
        region_value: '53',
        classMap: 'FR-dep-22'
    },
    {
        value: '23',
        label: 'Creuse',
        region: 'Nouvelle-Aquitaine',
        region_value: '75',
        classMap: 'FR-dep-23'
    },
    {
        value: '24',
        label: 'Dordogne',
        region: 'Nouvelle-Aquitaine',
        region_value: '75',
        classMap: 'FR-dep-24'
    },
    {
        value: '25',
        label: 'Doubs',
        region: 'Bourgogne-Franche-Comté',
        region_value: '27',
        classMap: 'FR-dep-25'
    },
    {
        value: '26',
        label: 'Drôme',
        region: 'Auvergne-Rhône-Alpes',
        region_value: '84',
        classMap: 'FR-dep-26'
    },
    {
        value: '27',
        label: 'Eure',
        region: 'Normandie',
        region_value: '28',
        classMap: 'FR-dep-27'
    },
    {
        value: '28',
        label: 'Eure-et-Loir',
        region: 'Centre-Val de Loire',
        region_value: '24',
        classMap: 'FR-dep-28'
    },
    {
        value: '29',
        label: 'Finistère',
        region: 'Bretagne',
        region_value: '53',
        classMap: 'FR-dep-29'
    },
    {
        value: '30',
        label: 'Gard',
        region: 'Occitanie',
        region_value: '76',
        classMap: 'FR-dep-30'
    },
    {
        value: '31',
        label: 'Haute-Garonne',
        region: 'Occitanie',
        region_value: '76',
        classMap: 'FR-dep-31'
    },
    {
        value: '32',
        label: 'Gers',
        region: 'Occitanie',
        region_value: '76',
        classMap: 'FR-dep-32'
    },
    {
        value: '33',
        label: 'Gironde',
        region: 'Nouvelle-Aquitaine',
        region_value: '75',
        classMap: 'FR-dep-33'
    },
    {
        value: '34',
        label: 'Hérault',
        region: 'Occitanie',
        region_value: '76',
        classMap: 'FR-dep-34'
    },
    {
        value: '35',
        label: 'Ille-et-Vilaine',
        region: 'Bretagne',
        region_value: '53',
        classMap: 'FR-dep-35'
    },
    {
        value: '36',
        label: 'Indre',
        region: 'Centre-Val de Loire',
        region_value: '24',
        classMap: 'FR-dep-36'
    },
    {
        value: '37',
        label: 'Indre-et-Loire',
        region: 'Centre-Val de Loire',
        region_value: '24',
        classMap: 'FR-dep-37'
    },
    {
        value: '38',
        label: 'Isère',
        region: 'Auvergne-Rhône-Alpes',
        region_value: '84',
        classMap: 'FR-dep-38'
    },
    {
        value: '39',
        label: 'Jura',
        region: 'Bourgogne-Franche-Comté',
        region_value: '27',
        classMap: 'FR-dep-39'
    },
    {
        value: '40',
        label: 'Landes',
        region: 'Nouvelle-Aquitaine',
        region_value: '75',
        classMap: 'FR-dep-40'
    },
    {
        value: '41',
        label: 'Loir-et-Cher',
        region: 'Centre-Val de Loire',
        region_value: '24',
        classMap: 'FR-dep-41'
    },
    {
        value: '42',
        label: 'Loire',
        region: 'Auvergne-Rhône-Alpes',
        region_value: '84',
        classMap: 'FR-dep-42'
    },
    {
        value: '43',
        label: 'Haute-Loire',
        region: 'Auvergne-Rhône-Alpes',
        region_value: '84',
        classMap: 'FR-dep-43'
    },
    {
        value: '44',
        label: 'Loire-Atlantique',
        region: 'Pays de la Loire',
        region_value: '52',
        classMap: 'FR-dep-44'
    },
    {
        value: '45',
        label: 'Loiret',
        region: 'Centre-Val de Loire',
        region_value: '24',
        classMap: 'FR-dep-45'
    },
    {
        value: '46',
        label: 'Lot',
        region: 'Occitanie',
        region_value: '76',
        classMap: 'FR-dep-46'
    },
    {
        value: '47',
        label: 'Lot-et-Garonne',
        region: 'Nouvelle-Aquitaine',
        region_value: '75',
        classMap: 'FR-dep-47'
    },
    {
        value: '48',
        label: 'Lozère',
        region: 'Occitanie',
        region_value: '76',
        classMap: 'FR-dep-48'
    },
    {
        value: '49',
        label: 'Maine-et-Loire',
        region: 'Pays de la Loire',
        region_value: '52',
        classMap: 'FR-dep-49'
    },
    {
        value: '50',
        label: 'Manche',
        region: 'Normandie',
        region_value: '28',
        classMap: 'FR-dep-50'
    },
    {
        value: '51',
        label: 'Marne',
        region: 'Grand Est',
        region_value: '44',
        classMap: 'FR-dep-51'
    },
    {
        value: '52',
        label: 'Haute-Marne',
        region: 'Grand Est',
        region_value: '44',
        classMap: 'FR-dep-52'
    },
    {
        value: '53',
        label: 'Mayenne',
        region: 'Pays de la Loire',
        region_value: '52',
        classMap: 'FR-dep-53'
    },
    {
        value: '54',
        label: 'Meurthe-et-Moselle',
        region: 'Grand Est',
        region_value: '44',
        classMap: 'FR-dep-54'
    },
    {
        value: '55',
        label: 'Meuse',
        region: 'Grand Est',
        region_value: '44',
        classMap: 'FR-dep-55'
    },
    {
        value: '56',
        label: 'Morbihan',
        region: 'Bretagne',
        region_value: '53',
        classMap: 'FR-dep-56'
    },
    {
        value: '57',
        label: 'Moselle',
        region: 'Grand Est',
        region_value: '44',
        classMap: 'FR-dep-57'
    },
    {
        value: '58',
        label: 'Nièvre',
        region: 'Bourgogne-Franche-Comté',
        region_value: '27',
        classMap: 'FR-dep-58'
    },
    {
        value: '59',
        label: 'Nord',
        region: 'Hauts-de-France',
        region_value: '32',
        classMap: 'FR-dep-59'
    },
    {
        value: '60',
        label: 'Oise',
        region: 'Hauts-de-France',
        region_value: '32',
        classMap: 'FR-dep-60'
    },
    {
        value: '61',
        label: 'Orne',
        region: 'Normandie',
        region_value: '28',
        classMap: 'FR-dep-61'
    },
    {
        value: '62',
        label: 'Pas-de-Calais',
        region: 'Hauts-de-France',
        region_value: '32',
        classMap: 'FR-dep-62'
    },
    {
        value: '63',
        label: 'Puy-de-Dôme',
        region: 'Auvergne-Rhône-Alpes',
        region_value: '84',
        classMap: 'FR-dep-63'
    },
    {
        value: '64',
        label: 'Pyrénées-Atlantiques',
        region: 'Nouvelle-Aquitaine',
        region_value: '75',
        classMap: 'FR-dep-64'
    },
    {
        value: '65',
        label: 'Hautes-Pyrénées',
        region: 'Occitanie',
        region_value: '76',
        classMap: 'FR-dep-65'
    },
    {
        value: '66',
        label: 'Pyrénées-Orientales',
        region: 'Occitanie',
        region_value: '76',
        classMap: 'FR-dep-66'
    },
    {
        value: '67',
        label: 'Bas-Rhin',
        region: 'Grand Est',
        region_value: '44',
        classMap: 'FR-dep-67'
    },
    {
        value: '68',
        label: 'Haut-Rhin',
        region: 'Grand Est',
        region_value: '44',
        classMap: 'FR-dep-68'
    },
    {
        value: '69',
        label: 'Rhône',
        region: 'Auvergne-Rhône-Alpes',
        region_value: '84',
        classMap: 'FR-dep-69'
    },
    {
        value: '70',
        label: 'Haute-Saône',
        region: 'Bourgogne-Franche-Comté',
        region_value: '27',
        classMap: 'FR-dep-70'
    },
    {
        value: '71',
        label: 'Saône-et-Loire',
        region: 'Bourgogne-Franche-Comté',
        region_value: '27',
        classMap: 'FR-dep-71'
    },
    {
        value: '72',
        label: 'Sarthe',
        region: 'Pays de la Loire',
        region_value: '52',
        classMap: 'FR-dep-72'
    },
    {
        value: '73',
        label: 'Savoie',
        region: 'Auvergne-Rhône-Alpes',
        region_value: '84',
        classMap: 'FR-dep-73'
    },
    {
        value: '74',
        label: 'Haute-Savoie',
        region: 'Auvergne-Rhône-Alpes',
        region_value: '84',
        classMap: 'FR-dep-74'
    },
    {
        value: '75',
        label: 'Paris',
        region: 'Île-de-France',
        region_value: '11',
        classMap: 'FR-dep-75'
    },
    {
        value: '76',
        label: 'Seine-Maritime',
        region: 'Normandie',
        region_value: '28',
        classMap: 'FR-dep-76'
    },
    {
        value: '77',
        label: 'Seine-et-Marne',
        region: 'Île-de-France',
        region_value: '11',
        classMap: 'FR-dep-77'
    },
    {
        value: '78',
        label: 'Yvelines',
        region: 'Île-de-France',
        region_value: '11',
        classMap: 'FR-dep-78'
    },
    {
        value: '79',
        label: 'Deux-Sèvres',
        region: 'Nouvelle-Aquitaine',
        region_value: '75',
        classMap: 'FR-dep-79'
    },
    {
        value: '80',
        label: 'Somme',
        region: 'Hauts-de-France',
        region_value: '32',
        classMap: 'FR-dep-80'
    },
    {
        value: '81',
        label: 'Tarn',
        region: 'Occitanie',
        region_value: '76',
        classMap: 'FR-dep-81'
    },
    {
        value: '82',
        label: 'Tarn-et-Garonne',
        region: 'Occitanie',
        region_value: '76',
        classMap: 'FR-dep-82'
    },
    {
        value: '83',
        label: 'Var',
        region: "Provence-Alpes-Côte d'Azur",
        region_value: '93',
        classMap: 'FR-dep-83'
    },
    {
        value: '84',
        label: 'Vaucluse',
        region: "Provence-Alpes-Côte d'Azur",
        region_value: '93',
        classMap: 'FR-dep-84'
    },
    {
        value: '85',
        label: 'Vendée',
        region: 'Pays de la Loire',
        region_value: '52',
        classMap: 'FR-dep-85'
    },
    {
        value: '86',
        label: 'Vienne',
        region: 'Nouvelle-Aquitaine',
        region_value: '75',
        classMap: 'FR-dep-86'
    },
    {
        value: '87',
        label: 'Haute-Vienne',
        region: 'Nouvelle-Aquitaine',
        region_value: '75',
        classMap: 'FR-dep-87'
    },
    {
        value: '88',
        label: 'Vosges',
        region: 'Grand Est',
        region_value: '44',
        classMap: 'FR-dep-88'
    },
    {
        value: '89',
        label: 'Yonne',
        region: 'Bourgogne-Franche-Comté',
        region_value: '27',
        classMap: 'FR-dep-89'
    },
    {
        value: '90',
        label: 'Territoire-de-Belfort',
        region: 'Bourgogne-Franche-Comté',
        region_value: '27',
        classMap: 'FR-dep-90'
    },
    {
        value: '91',
        label: 'Essonne',
        region: 'Île-de-France',
        region_value: '11',
        classMap: 'FR-dep-91'
    },
    {
        value: '92',
        label: 'Hauts-de-Seine',
        region: 'Île-de-France',
        region_value: '11',
        classMap: 'FR-dep-92'
    },
    {
        value: '93',
        label: 'Seine-Saint-Denis',
        region: 'Île-de-France',
        region_value: '11',
        classMap: 'FR-dep-93'
    },
    {
        value: '94',
        label: 'Val-de-Marne',
        region: 'Île-de-France',
        region_value: '11',
        classMap: 'FR-dep-94'
    },
    {
        value: '95',
        label: "Val-d'Oise",
        region: 'Île-de-France',
        region_value: '11',
        classMap: 'FR-dep-95'
    },
    {
        value: '971',
        label: 'Guadeloupe',
        region: 'Guadeloupe',
        region_value: '01',
        classMap: 'FR-DOM-971'
    },
    {
        value: '972',
        label: 'Martinique',
        region: 'Martinique',
        region_value: '02',
        classMap: 'FR-DOM-972'
    },
    {
        value: '973',
        label: 'Guyane française',
        region: 'Guyane',
        region_value: '03',
        classMap: 'FR-DOM-973'
    },
    {
        value: '974',
        label: 'Réunion',
        region: 'La Réunion',
        region_value: '04',
        classMap: 'FR-DOM-974'
    },
    {
        value: '975',
        label: 'Nouvelle-Calédonie',
        region: 'Nouvelle-Calédonie',
        region_value: '05',
        classMap: 'FR-DOM-975'
    },
    {
        value: '976',
        label: 'Mayotte',
        region: 'Mayotte',
        region_value: '06',
        classMap: 'FR-DOM-976'
    }
]

const reg = [
    {
        value: '84',
        label: 'Auvergne-Rhône-Alpes',
        classMap: 'FR-reg-84'
    },
    {
        value: '32',
        label: 'Hauts-de-France',
        classMap: 'FR-reg-32'
    },
    {
        value: '93',
        label: "Provence-Alpes-Côte d'Azur",
        classMap: 'FR-reg-93'
    },
    {
        value: '44',
        label: 'Grand Est',
        classMap: 'FR-reg-44'
    },
    {
        value: '76',
        label: 'Occitanie',
        classMap: 'FR-reg-76'
    },
    {
        value: '28',
        label: 'Normandie',
        classMap: 'FR-reg-28'
    },
    {
        value: '75',
        label: 'Nouvelle-Aquitaine',
        classMap: 'FR-reg-75'
    },
    {
        value: '24',
        label: 'Centre-Val de Loire',
        classMap: 'FR-reg-24'
    },
    {
        value: '27',
        label: 'Bourgogne-Franche-Comté',
        classMap: 'FR-reg-27'
    },
    {
        value: '53',
        label: 'Bretagne',
        classMap: 'FR-reg-53'
    },
    {
        value: '94',
        label: 'Corse',
        classMap: 'FR-reg-94'
    },
    {
        value: '52',
        label: 'Pays de la Loire',
        classMap: 'FR-reg-52'
    },
    {
        value: '11',
        label: 'Île-de-France',
        classMap: 'FR-reg-11'
    },
    {
        value: '01',
        label: 'Guadeloupe',
        classMap: 'FR-DOM-971'
    },
    {
        value: '02',
        label: 'Martinique',
        classMap: 'FR-DOM-972'
    },
    {
        value: '03',
        label: 'Guyane',
        classMap: 'FR-DOM-973'
    },
    {
        value: '04',
        label: 'La Réunion',
        classMap: 'FR-DOM-974'
    },
    {
        value: '06',
        label: 'Mayotte',
        classMap: 'FR-DOM-976'
    }
]

export const getHexaFromName = function (colorName, options = undefined) {
    if (colorName === "green-emeraude") return "#00A95F" //#00e180
    else if (colorName === "yellow-moutarde") return "#C3992A" //#f5c137
    else if (colorName === "pink-tuile") return "#CE614A" //#e89082
    else if (colorName === "blue-ecume") return "#465F9D" //#6f89d1
    else return window.dsfr.colors.getColor('artwork', 'major', colorName, options)
}

const patternDraw = [
    'plus',
    'cross',
    'dash',
    'cross-dash',
    'dot',
    'dot-dash',
    'disc',
    'ring',
    'line',
    'line-vertical',
    'weave',
    'zigzag',
    'zigzag-vertical',
    'diagonal',
    'diagonal-right-left',
    'square',
    'box',
    'triangle',
    'triangle-inverted',
    'diamond',
    'diamond-box'
]

export const getAllColors = function () {
    return colorsDSFR
}

export const getAllPattern = function () {
    return patternDraw
}

const acad = [
    {
        value: '01',
        label: 'Académie de Clermont-Ferrand'
    },
    {
        value: '02',
        label: 'Académie de Grenoble'
    },
    {
        value: '03',
        label: 'Académie de Lyon'
    },
    {
        value: '04',
        label: 'Académie de Besançon'
    },
    {
        value: '05',
        label: 'Académie de Dijon'
    },
    {
        value: '06',
        label: 'Académie de Rennes'
    },
    {
        value: '07',
        label: "Académie d'Orléans-Tours"
    },
    {
        value: '08',
        label: 'Académie de Corse'
    },
    {
        value: '09',
        label: 'Académie de Nancy-Metz'
    },
    {
        value: '10',
        label: 'Académie de Reims'
    },
    {
        value: '11',
        label: 'Académie de Strasbourg'
    },
    {
        value: '14',
        label: "Académie d'Amiens"
    },
    {
        value: '15',
        label: 'Académie de Lille'
    },
    {
        value: '16',
        label: 'Académie de Créteil'
    },
    {
        value: '17',
        label: 'Académie de Paris'
    },
    {
        value: '18',
        label: 'Académie de Versailles'
    },
    {
        value: '20',
        label: 'Académie de Normandie'
    },
    {
        value: '21',
        label: 'Académie de Bordeaux'
    },
    {
        value: '22',
        label: 'Académie de Limoges'
    },
    {
        value: '23',
        label: 'Académie de Poitiers'
    },
    {
        value: '24',
        label: 'Académie de Montpellier'
    },
    {
        value: '25',
        label: 'Académie de Toulouse'
    },
    {
        value: '26',
        label: 'Académie de Nantes'
    },
    {
        value: '27',
        label: "Académie d'Aix-Marseille"
    },
    {
        value: '28',
        label: 'Académie de Nice'
    },
    {
        value: '971',
        label: 'Académie de Guadeloupe'
    },
    {
        value: '972',
        label: 'Académie de Martinique'
    },
    {
        value: '973',
        label: 'Académie de Guyane'
    },
    {
        value: '974',
        label: 'Académie de La Réunion'
    },
    {
        value: '976',
        label: 'Académie de Mayotte'
    }
]

export const getDep = function (code) {
    return dep.find(obj => {
        return obj.value === code
    })
}

export const getReg = function (code) {
    return reg.find(obj => {
        return obj.value === code
    })
}

export const getAllReg = function (code) {
    const allReg = []
    reg.forEach(element => allReg.push(element.value))
    return allReg
}

export const getAcad = function (code) {
    return acad.find(obj => {
        return obj.value === code
    })
}

export const getClassMap = function (code, level) {
    let obj

    if (level === 'reg') {
        obj = getReg(code)
    } else if (level === 'dep') {
        obj = getDep(code)
    }

    return obj.classMap
}

export const getDepsFromReg = function (code) {
    const depObj = dep.filter(obj => {
        return obj.region_value === code
    })

    const res = []
    depObj.forEach(function (dep, j) {
        res.push(dep.value)
    })
    return res
}

const allToken = {
    'background-contrats-grey': {
        light: '#EEEEEE',
        dark: '#242424'
    },
    'text-mention-grey': {
        light: '#666666',
        dark: '#929292'
    },
    'border-default-grey': {
        light: '#DDDDDD',
        dark: '#353535'
    }
}

export const getHexaFromToken = function (token, theme) {
    return allToken[token][theme]
}

export const mixin = {
    methods: {
        capitalize,
        convertStringToLocaleNumber,
        convertFloatToHuman,
        convertIntToHuman,
        convertIntToHumanTable,
        convertDateToHuman,
        testIfNaN,
        getDep,
        getReg,
        getAcad,
        getDepsFromReg,
        getAllColors,
        getHexaFromName,
        getAllPattern,
        getClassMap,
        getAllReg,
        getHexaFromToken
    }
}

export function initDates() {
    const currentDate = new Date();

    const addDays = (date, days) => {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };

    const addMonths = (date, months) => {
        let result = new Date(date);
        result.setMonth(result.getMonth() + months);
        return result;
    };

    const startDate = addMonths(currentDate, -1);
    const endDate = addDays(currentDate, -1);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate)
    };
}

export function changeDateFormat(date) {
    if (date !== undefined) {
        let dateArray = date.split("-");
        return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
    } else {
        return "";
    }
}

export function getColor(selectedFilterTag) {
    let colors = [];

    if (selectedFilterTag.includes("tous-les-axes")) {
        colors = [
            "green-emeraude",
            "yellow-moutarde",
            "pink-tuile",
            "blue-ecume",
        ];
    } else {
        if (selectedFilterTag.includes("TRANSITION ECOLOGIQUE")) {
            colors = colors.concat(["green-emeraude"]);
        }
        if (selectedFilterTag.includes("PLEIN EMPLOI")) {
            colors = colors.concat(["yellow-moutarde"]);
        }
        if (selectedFilterTag.includes("NOUVEAUX PROGRES ET SERVICES PUBLICS")) {
            colors = colors.concat(["pink-tuile"]);
        }
        if (selectedFilterTag.includes("ORDRE REPUBLICAIN ET ENGAGEMENT")) {
            colors = colors.concat(["blue-ecume"]);
        }
    }

    return colors;
}

export function updateDisplayValues(charts, newValue, source) {
    for (let key in charts) {
        if (charts[key].object.source.includes(source)) {
            charts[key].display = newValue;
        }
    }
};

export function updateDisplayValuesList(chartList, newValue, sourcesList){
    for(let graph of chartList){
      for( let src of sourcesList){
        updateDisplayValues(graph, newValue, src);
      }
    }
};

export function updateDisplayValue(objectSource, sources) {
    return sources.find(source => source.value === objectSource).selected || sources.find(source => source.value === "all-types").selected;
};

export const generateAxes = (axesToGenerate, axes, isMainScreen, setAxes) => {
    // si pas mainScreen, supprimer ALL
    if (!isMainScreen) {
      // Supprimer l'élément avec value 'ALL' s'il est présent
      let filteredAxes = axes.filter(axe => axe.value !== 'ALL');
      setAxes(filteredAxes);
    } else {
      // Ajouter l'élément avec value 'ALL' s'il n'est pas présent
      const isAllAxes = axesToGenerate.some(axe => axe.value === 'ALL');
      if (!isAllAxes) {
        // Ajouter 'ALL' en première position dans axes
        axes.unshift({ value: 'ALL', label: 'Tous les axes', selected: false });
      }
    }
};

export const updateAxesSelections = (axes, selectedAxes) => {
    axes.forEach(axe => {
      axe.selected = selectedAxes.includes(axe.value);
    });
};

export const selectFilterAxe = (axes, selectedAxe, isMainScreen, updateAxesSelections,  setSelectedAxes, addSelectedAxe, removeSelectedAxe) => {
    if (!isMainScreen) {
        updateAxesSelections([selectedAxe.value]);
        setSelectedAxes([selectedAxe.value]);
    }

    if (isMainScreen) {
      selectedAxe.selected = !selectedAxe.selected;

      // Si ALL
      if (selectedAxe.value === 'ALL') {
        setSelectedAxes(['ALL']);
        axes.forEach(axe => {
          axe.selected = axe.value === 'ALL';
        });
      }

      // Si pas ALL
      if (selectedAxe.value !== 'ALL') {
        // Passe le selected de l'axe ALL à false
        axes.forEach(axe => {
          if (axe.value === 'ALL') {
            axe.selected = false;
          }
        });
        removeSelectedAxe('ALL');
        // et selectionné
        if (selectedAxe.selected) {
            addSelectedAxe(selectedAxe.value);
        } else { // et deselectionné
            removeSelectedAxe(selectedAxe.value);
        };
      }

      const allTagsUnSelected = axes
        .filter(axe => axe.value !== 'ALL')
        .every(axe => axe.selected === false);

      if (allTagsUnSelected) {
        axes.forEach(axe => {
          if (axe.value === 'ALL') {
            axe.selected = true;
          }
        });
        setSelectedAxes(['ALL']);
      }
    }
};

export const generateSources = (sourcesToGenerate, sources) => {
      // Ajouter l'élément avec value 'all-types' s'il n'est pas présent
      const isAllSources = sourcesToGenerate.some(source => source.value === 'all-types');
      if (!isAllSources) {
        // Ajouter 'all-types' en première position dans sources
        sources.unshift({ value: 'all-types', label: 'Tous les types de données', selected: false });
      }
};

export const updateSourcesSelections = (sources, selectedSources) => {
    sources.forEach(source => {
        source.selected = selectedSources.includes(source.value);
    });
};

export const selectFilterSource = (sources, selectedSource,  updateSourcesSelections,  setSelectedSources, addSelectedSource, removeSelectedSource) => {

    selectedSource.selected = !selectedSource.selected;
      // Si 'all-types'
      if (selectedSource.value === 'all-types') {
        updateSourcesSelections([selectedSource.value]);
        setSelectedSources(['all-types']);
        sources.forEach(source => {
          source.selected = source.value === 'all-types';
        });
      }

      // Si pas 'all-types'
      if (selectedSource.value !== 'all-types') {
        // Passe le selected de l'axe 'all-types' à false
        sources.forEach(source => {
          if (source.value === 'all-types') {
            source.selected = false;
          }
        });
        removeSelectedSource('all-types');
        // et selectionné
        if (selectedSource.selected) {
            addSelectedSource(selectedSource.value);
        } else { // et deselectionné
            removeSelectedSource(selectedSource.value);
        };
      }

      const allDataTypeTagsUnSelected = sources
        .filter(source => source.value !== 'all-types')
        .every(source => source.selected === false);

      if (allDataTypeTagsUnSelected) {
        sources.forEach(source => {
          if (source.value === 'all-types') {
            source.selected = true;
          }
        });
        setSelectedSources(['all-types']);
      }
};

export const getPeriodLabel = (selectedPeriod) => {
    switch (selectedPeriod) {
        case "année":
            return "12 derniers mois";
        case "mois":
            return "30 derniers jours";
        case "semaine":
            return "7 derniers jours";
    }
};
