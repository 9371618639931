import Vue from "vue";
import Router from "vue-router";
import store from "./store/store";

Vue.use(Router);

const authenticationRequired = process.env.VUE_APP_KEYCLOAK_AVAILABLE === "true";

const router = new Router({
    mode: "history",
    linkActiveClass: '',
    linkExactActiveClass: '',
    routes: [
        {
            path: "/",
            name: "home",
            component: () =>
                import("./views/AccueilPage.vue"),
            meta: {
                requiresAuth: authenticationRequired,
            },
            redirect: "/accueil"
        },
        {
            path: "/accueil",
            name: "accueil",
            component: () =>
                import("./views/AccueilPage.vue"),
            meta: {
                requiresAuth: authenticationRequired,
                title: "Accueil"
            },
        },
        {
            path: "/tableaux-de-bord",
            name: "tableaux-de-bord",
            component: () =>
                import("./views/DashboardPage.vue"),
            meta: {
                requiresAuth: authenticationRequired,
                title: "Tableaux de bord",
                description: "Interagissez avec les données de l'Observatoire des priorités du gouvernement mises à jour quotidiennement"
            },
            children: [
                {
                    path: "/indicateurs",
                    name: "vue-synthetique",
                    component: () =>
                        import("./focus/SyntheticView.vue"),
                    meta: {
                        requiresAuth: authenticationRequired,
                        title: "Vision transverse",
                        description: "Comparer les différentes priorités du Gouvernement à travers une sélection d’indicateurs par verticale (visibilité, acteurs, sujets d’intérêt)",
                    }
                },
                {
                    path: "/visibilite",
                    name: "visibilite",
                    component: () =>
                        import("./focus/FocusVisibility.vue"),
                    meta: {
                        requiresAuth: authenticationRequired,
                        title: "Vue détaillée visibilité",
                        description: "Explorer la visibilité de la priorité du Gouvernement sélectionnée, par types de support et dans le temps"
                    }
                },
                {
                    path: "/acteurs",
                    name: "acteurs",
                    component: () =>
                        import("./focus/FocusActors.vue"),
                    meta: {
                        requiresAuth: authenticationRequired,
                        title: "Vue détaillée acteurs",
                        description: "Identifier les parties prenantes qui contribuent à la visibilité de la priorité du Gouvernement sélectionnée (sphère étatique, médias ou société civile)"
                    }
                },
                {
                    path: "/sujets-interets",
                    name: "sujets-interets",
                    component: () =>
                        import("./focus/FocusSubjects"),
                    meta: {
                        requiresAuth: authenticationRequired,
                        title: "Vue détaillée sujets d'intérêts",
                        description: "Découvrir les thématiques qui émergent au sein de la priorité du Gouvernement sélectionnée et retiennent l’attention des Français"
                    }
                },
            ],
        },
        {
            path: "/glossaire-et-ressources",
            name: "glossaire-et-ressources",
            component: () =>
                import("./views/LexiquePage.vue"),
            meta: {
                requiresAuth: authenticationRequired,
                title: "Glossaire et ressources",
            },
        },
        {
            path: "/mentions-legales",
            name: "mentions-legales",
            component: () =>
                import("./views/LegalMentions.vue"),
            meta: {
                requiresAuth: authenticationRequired,
                title: "Mentions légales",
            },
        },
        {
            path: "/donnees-personnelles",
            name: "donnees-personnelles",
            component: () =>
                import("./views/PrivateDataPage.vue"),
            meta: {
                requiresAuth: authenticationRequired,
                title: "Données personnelles et cookies",
            },
        },
        {
            path: "/accessibilite",
            name: "accessibilite",
            component: () =>
                import("./views/AccessibilityPage.vue"),
            meta: {
                requiresAuth: authenticationRequired,
                title: "Accessibilité",
            },
        },
        {
            path: "/plan-du-site",
            name: "plan-du-site",
            component: () =>
                import("./views/PlanDuSitePage.vue"),
            meta: {
                requiresAuth: authenticationRequired,
                title: "Plan du Site",
            }
        },
    ],
    scrollBehavior(to, from, savePosition) {
        return {x: 0, y: 0};
    },
});

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

router.beforeEach(async (to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth) && !router.app.$keycloak.authenticated) {
        router.app.$keycloak.login();
    } else {
        next();
    }
});

router.beforeEach(async (to, from, next) => {
    await store.dispatch('setMainScreen', to.name === 'vue-synthetique');
    if (to.name === 'vue-synthetique') {
        await store.dispatch('setSelectedAxes', ['ALL']);
    } else if (store.getters.getSelectedAxes.includes('ALL') || store.getters.getSelectedAxes.length !== 1) {
        await store.dispatch('setSelectedAxes', ['PLEIN EMPLOI']);
    }
    next();
});

export default router;
