<template>
  <header role="banner" class="fr-header">
    <div class="fr-header__body">
      <div class="fr-container">
        <div class="fr-header__body-row">
          <div class="fr-header__brand fr-enlarge-link">
            <div class="fr-header__brand-top">
              <div class="fr-header__logo">
                <p class="fr-logo">Gouvernement</p>
              </div>
              <div class="fr-header__navbar">
                <button data-fr-opened="false" aria-controls="navigation-modal" id="navbar-menu-btn" title="Menu"
                        class="fr-btn--menu fr-btn">Menu
                </button>
              </div>
            </div>
            <div class="fr-header__service">
              <a href="/" :title="`Accueil | ${websiteName}`">
                <p class="fr-header__service-title">
                  {{ websiteName }}
                  <!-- <span class="fr-badge fr-badge--sm fr-badge--green-emeraude">BETA</span> -->
                </p>
              </a>
            </div>
          </div>
          <div class="fr-header__tools">
            <div class="fr-header__tools-links">
              <!--
              The following <div> wrapping fr-translate avoids a lot of custom CSS for the connected header.
              So please let it be until the official component is released
              -->
              <div>
                <nav class="fr-connected-header fr-translate fr-nav" id="connected-header">
                  <div class="fr-nav__item">
                    <button aria-controls="connected-header-menu" aria-expanded="false" title="Mon espace utilisateur"
                            id="connected-header-menu-btn" class="fr-translate__btn fr-btn">Mon espace
                    </button>
                    <div class="fr-collapse fr-translate__menu fr-menu" id="connected-header-menu">
                      <div class="fr-connected-header__menu">
                        <div class="fr-connected-header__menu-header">
                          <p class="fr-text--sm fr-text--bold">{{ userName }}</p>
                          <p class="fr-text--xs fr-text-mention--grey">{{ userMail }}</p>
                        </div>
                        <ul class="fr-menu__list">
                          <li>
                            <a class="fr-nav__link fr-icon-user-line fr-btn--icon-left" id="user-menu-edit-user-profile"
                               :href="editUserProfileUrl" title="Éditer mon profil utilisateur">Mon profil (Kiosque)
                            </a>
                          </li>
                        </ul>
                        <div class="fr-connected-header__menu-footer">
                          <a id="user-menu-logout" :href="logoutUrl"
                             class="fr-btn fr-icon-logout-box-r-line fr-btn--icon-left fr-btn--tertiary fr-btn--sm"
                             title="Se déconnecter">Se déconnecter</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fr-header__menu fr-modal" id="navigation-modal" aria-labelledby="navbar-menu-btn">
      <div class="fr-container">
        <button aria-controls="navigation-modal" id="close-modal-btn" title="Fermer" class="fr-btn--close fr-btn">
          Fermer
        </button>
        <div class="fr-header__menu-links"></div>
        <app-navigation/>
      </div>
    </div>
  </header>
</template>

<script>
import NavigationDsfr from './Navigation.vue';
import {WebsiteName, KioskUrl} from '@/utils';
import '../styles/components/header.scss'

export default {
  name: "HeaderDsfr",
  components: {
    "app-navigation": NavigationDsfr
  },
  data() {
    return {
      websiteName: WebsiteName,
      editUserProfileUrl: `${KioskUrl}/profil`,
      userName: this.$keycloak?.fullName ?? "Test username",
      userMail: this.$keycloak?.idTokenParsed.email ?? " test.username@pm.gouv.fr",
      logoutUrl: this.$keycloak?.createLogoutUrl() ?? "#"
    };
  }
};
</script>
