import Vue from 'vue'
import App from './App.vue'
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import router from './router'
import store from "./store/store";

// Tarteaucitron
import './services/tarteaucitron/init'
import './styles/tarteaucitron.scss'

// DSFR
import './services/dsfr_analytics_config.js'
import '@gouvfr/dsfr/dist/dsfr/dsfr.module.min';
import '@gouvfr/dsfr/dist/analytics/analytics.module.min'
import '@gouvfr/dsfr/dist/dsfr/dsfr.min.css';
import '@gouvfr/dsfr/dist/utility/colors/colors.min.css';
import '@gouvfr/dsfr/dist/utility/icons/icons-system/icons-system.min.css';
import '@gouvfr/dsfr/dist/utility/icons/icons-user/icons-user.min.css';
import '@gouvfr/dsfr/dist/utility/icons/icons-business/icons-business.min.css';
import '@gouvfr/dsfr/dist/utility/icons/icons-editor/icons-editor.min.css';

// Importation des widgets
const scriptElement = document.createElement('script')
scriptElement.setAttribute('src', 'https://barometre.gouvernement.fr/widgets/js/app.js')
document.body.appendChild(scriptElement)
// Fin importation des widgets


Vue.config.productionTip = false

function initializeVueApp() {
    new Vue({
        el: '#app',
        router,
        store,
        render: h => h(App),
    });
}

// Once keycloack is definitely up and running, remove that weird condition
if (process.env.VUE_APP_KEYCLOAK_AVAILABLE === 'true') {
    Vue.use(VueKeyCloak, {
        config: {
            realm: process.env.VUE_APP_KEYCLOAK_REALM,
            url: process.env.VUE_APP_KEYCLOAK_URL,
            clientId: process.env.VUE_APP_KEYCLOAK_CLIENT
        },
        init: {
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: `${location.origin}${process.env.VUE_APP_PREFIX_PATH}/silent-check-sso.html`,
        },
        onReady: () => {
            initializeVueApp()
        },
    })
} else {
    console.warn('Keycloak is not enabled. Initializing Vue app without Keycloak.');
    initializeVueApp()
}
