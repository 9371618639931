<template>
  <nav class="fr-nav" id="navigation-menu" role="navigation" aria-label="Menu principal">
    <ul class="fr-nav__list">
      <!-- Calendrier -->
      <li class="fr-nav__item">
        <a id="nav-item-calendrier" class="fr-nav__link" href="https://kiosque.communication.gouv.fr/calendrier">Calendrier</a>
      </li>

      <!-- Données -->
      <li class="fr-nav__item">
        <button class="fr-nav__btn" aria-expanded="false" aria-controls="mega-menu-donnees" aria-current="false" id="nav-item-données">Données</button>
        <div class="fr-collapse fr-mega-menu" id="mega-menu-donnees" tabindex="-1">
          <div class="fr-container fr-container--fluid fr-container-lg">
            <div class="fr-grid-row fr-grid-row-lg--gutters">
              <div class="fr-col-12 fr-mb-n3v">
                <button aria-controls="mega-menu-donnees" id="mega-menu-donnees-close-btn" title="Fermer" class="fr-btn--close fr-btn">Fermer</button>
              </div>
              <div class="fr-col-12 fr-col-lg-8 fr-col-offset-lg-4--right">
                <div class="fr-mega-menu__leader">
                  <h4 class="fr-h4 fr-mb-2v">Des données claires pour des actions efficaces</h4>
                  <p class="fr-hidden fr-unhidden-lg">Tirez profit des données de l’État pour maximiser vos performances</p>
                </div>
              </div>
              <div class="fr-col-12 fr-col-lg-4">
                <h5 class="fr-mega-menu__category">
                  <a class="fr-nav__link" href="#" target="_self" id="nav-link-opinion-veille">Opinion et veille</a>
                </h5>
                <ul class="fr-mega-menu__list">
                  <li>
                    <a class="fr-nav__link"
                        href="https://kiosque.communication.gouv.fr/actualites/opinion-et-veille/revue-des-invites-politiques"
                        target="_blank"
                        id="nav-link-revues-invites-politiques"
                        title="Revues des invités politiques - nouvelle fenêtre">Revues des invités politiques</a>
                  </li>
                  <li>
                    <a class="fr-nav__link"
                        href="https://kiosque.communication.gouv.fr/actualites/opinion-et-veille/grille-des-invites-medias"
                        target="_blank"
                        id="nav-link-grille-media"
                        title="Grille média - nouvelle fenêtre">Grille média</a>
                  </li>
                  <li>
                    <a class="fr-nav__link"
                        href="https://kiosque.communication.gouv.fr/actualites/opinion-et-veille/synthese-des-chroniques-politiques"
                        target="_blank"
                        id="nav-link-synthse-chroniques-politiques"
                        title="Synthèse des chroniques politiques - nouvelle fenêtre">Synthèse des chroniques politiques</a>
                  </li>
                  <li>
                      <a class="fr-nav__link" target="_self" id="nav-link-sondages">
                        Sondages
                        <span class="fr-badge fr-badge--sm fr-background-flat--blue-france fr-text-inverted--grey fr-ml-1v">à venir</span>
                      </a>
                  </li>
                </ul>
              </div>
              <div class="fr-col-12 fr-col-lg-4">
                <h5 class="fr-mega-menu__category">
                  <a class="fr-nav__link" href="#" target="_self" id="nav-link-priorites-gouvernement">Priorités du Gouvernement</a>
                </h5>
                <ul class="fr-mega-menu__list">
                  <li>
                    <router-link class="fr-nav__link" :to="{ name: 'accueil' }" target="_self" id="nav-link-observatoire-priorites">Observatoire des Priorités du Gouvernement </router-link>
                  </li>
                  <li>
                    <router-link class="fr-nav__link" :to="{ name: 'vue-synthetique' }" target="_self" id="nav-link-observatoire-principaux-indicateurs">Principaux indicateurs de l’Observatoire</router-link>
                  </li>
                  <li>
                    <a class="fr-nav__link"
                        href="https://www.info.gouv.fr/politiques-prioritaires"
                        target="_blank"
                        id="nav-link-barometre-resultats-action-publique"
                        title="Baromètre des résultats de l’action publique - nouvelle fenêtre">Baromètre des résultats de l’action publique</a>
                  </li>
                </ul>
              </div>
              <div class="fr-col-12 fr-col-lg-4">
                <h5 class="fr-mega-menu__category">
                  <a class="fr-nav__link" href="#" target="_self" id="nav-link-ecosysteme-numerique">Écosystème numérique</a>
                </h5>
                <ul class="fr-mega-menu__list">
                  <li>
                    <a class="fr-nav__link"
                        href="https://audience.communication.gouv.fr/visites"
                        target="_blank"
                        id="nav-link-audience-sites-etat"
                        title="Audience des sites de l’État - nouvelle fenêtre">Audience des sites de l’État</a>
                  </li>
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-audience-rs">Audience des réseaux sociaux</a>
                  </li>
                  <li>
                    <a class="fr-nav__link"
                        href="https://audience.communication.gouv.fr/sites"
                        target="_blank"
                        id="nav-link-paranorama-sites-etat"
                        title="Panorama des sites de l’État - nouvelle fenêtre">Panorama des sites de l’État</a>
                  </li>
                  <li>
                    <a class="fr-nav__link" target="_self" id="nav-link-panorama-reseaux-sociaux">Panorama des réseaux sociaux</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>

      <!-- Dispositifs de communication -->
      <li class="fr-nav__item">
        <button class="fr-nav__btn" aria-expanded="false" aria-controls="mega-menu-comm" aria-current="false">Dispositifs de communication</button>
        <div class="fr-collapse fr-mega-menu" id="mega-menu-comm" tabindex="-1">
          <div class="fr-container fr-container--fluid fr-container-lg">
            <div class="fr-grid-row fr-grid-row-lg--gutters">
              <div class="fr-col-12 fr-mb-n3v">
                <button aria-controls="mega-menu-comm" id="mega-menu-comm-close-btn" title="Fermer" class="fr-btn--close fr-btn">Fermer</button>
              </div>
              <div class="fr-col-12 fr-col-lg-4">
                <ul class="fr-mega-menu__list">
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-etudes-cas">Études de cas</a>
                  </li>
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-campagnes">Campagnes</a>
                  </li>
                  <li>
                      <a class="fr-nav__link" href="#" target="_self" id="nav-link-objets-ministeriels">Objets interministériels</a>
                  </li>
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-bilans">Bilans</a>
                  </li>
                  <li>
                    <a class="fr-nav__link fr-text-action-high--blue-france"
                        href="https://kiosque.communication.gouv.fr/dispositifs-de-communication"
                        target="_blank"
                        title="Tous les dispositifs de communication - nouvelle fenêtre"
                        id="nav-link-dispositifs-com"
                        rel="noopener external">
                      <span class="fr-link" data-fr-analytics-action="false" id="nav-link-span-dispositifs-com">Tous les dispositifs de communication</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>

      <!-- Référentiels -->
      <li class="fr-nav__item">
        <button class="fr-nav__btn" aria-expanded="false" aria-controls="mega-menu-referentiels" aria-current="false">Référentiels</button>
        <div class="fr-collapse fr-mega-menu" id="mega-menu-referentiels" tabindex="-1">
          <div class="fr-container fr-container--fluid fr-container-lg">
            <div class="fr-grid-row fr-grid-row-lg--gutters">
              <div class="fr-col-12 fr-mb-n3v">
                <button aria-controls="mega-menu-referentiels" id="mega-menu-referentiels-close-btn" title="Fermer" class="fr-btn--close fr-btn">Fermer</button>
              </div>
              <div class="fr-col-12 fr-col-lg-8 fr-col-offset-lg-4--right">
                <div class="fr-mega-menu__leader">
                  <h4 class="fr-h4 fr-mb-2v">Des référentiels pour coordonner la communication</h4>
                  <p class="fr-hidden fr-unhidden-lg">Les textes clés pour communiquer au nom de l’Etat</p>
                </div>
              </div>
              <div class="fr-col-12 fr-col-lg-4">
                <h5 class="fr-mega-menu__category">
                  <a class="fr-nav__link" href="#" target="_self" id="nav-link-main-chartes-standards">Chartes et standards</a>
                </h5>
                <ul class="fr-mega-menu__list">
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-chartes-standards">Chartes et standards</a>
                  </li>
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-chartes-graphiques">Chartes graphiques</a>
                  </li>
                  <li>
                      <a class="fr-nav__link" href="#" target="_self" id="nav-link-dsfr">Système de Design de l’État</a>
                  </li>
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-analytics">Performance et analytics</a>
                  </li>
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-chartes-a11y">Accessibilité</a>
                  </li>
                </ul>
              </div>
              <div class="fr-col-12 fr-col-lg-4">
                <h5 class="fr-mega-menu__category">
                  <a class="fr-nav__link" href="#" target="_self" id="nav-link-recit-commun-ppg">Récit commun et Politiques Prioritaires</a>
                </h5>
                <ul class="fr-mega-menu__list">
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-recit-commun">Récit commun</a>
                  </li>
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-ppg">Politiques Prioritaires</a>
                  </li>
                </ul>
              </div>
              <div class="fr-col-12 fr-col-lg-4">
                <h5 class="fr-mega-menu__category">
                  <a class="fr-nav__link" href="#" target="_self" id="nav-link-cadre-reglementaire">Cadre réglementaire</a>
                </h5>
                <ul class="fr-mega-menu__list">
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-marches-publics">Marchés publics</a>
                  </li>
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-circulaires">Circulaires</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>

      <!-- Réseaux -->
      <li class="fr-nav__item">
        <button class="fr-nav__btn" aria-expanded="false" aria-controls="mega-menu-reseaux" aria-current="false">Réseaux</button>
        <div class="fr-collapse fr-mega-menu" id="mega-menu-reseaux" tabindex="-1">
          <div class="fr-container fr-container--fluid fr-container-lg">
            <div class="fr-grid-row fr-grid-row-lg--gutters">
              <div class="fr-col-12 fr-mb-n3v">
                <button aria-controls="mega-menu-reseaux" id="mega-menu-reseaux-close-btn" title="Fermer" class="fr-btn--close fr-btn">Fermer</button>
              </div>
              <div class="fr-col-12 fr-col-lg-4">
                <h5 class="fr-mega-menu__category">
                  <a class="fr-nav__link" href="#" target="_self" id="nav-link-ressources-partagees">Ressources partagées</a>
                </h5>
                <ul class="fr-mega-menu__list">
                  <li>
                    <a class="fr-nav__link"
                        href="https://kiosque.communication.gouv.fr/annuaire"
                        target="_blank"
                        id="nav-link-annuaire"
                        title="Annuaire - nouvelle fenêtre">Annuaire</a>
                  </li>
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-formations">Formations</a>
                  </li>
                  <li>
                      <a class="fr-nav__link" href="#" target="_self" id="nav-link-guide-bienvenue">Guide de bienvenue</a>
                  </li>
                </ul>
              </div>
              <div class="fr-col-12 fr-col-lg-4">
                <h5 class="fr-mega-menu__category">
                  <a class="fr-nav__link" href="#" target="_self" id="nav-link-vie-sphere">Vie de la sphère</a>
                </h5>
                <ul class="fr-mega-menu__list">
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-actualites">Actualités</a>
                  </li>
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-evenements">Événements</a>
                  </li>
                  <li>
                    <a class="fr-nav__link"
                        href="https://choisirleservicepublic.gouv.fr/nos-offres/filtres/domaine/3509/"
                        target="_blank"
                        id="nav-link-offres-emploi"
                        title="Offres d’emploi - nouvelle fenêtre">Offres d’emploi</a>
                  </li>
                </ul>
              </div>
              <div class="fr-col-12 fr-col-lg-4">
                <h5 class="fr-mega-menu__category">
                  <a class="fr-nav__link" href="#" target="_self" id="nav-link-par-reseaux">Par réseau</a>
                </h5>
                <ul class="fr-mega-menu__list columns-2">
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-par-reseaux-a11y">Accessibilité</a>
                  </li>
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-par-reseaux-analytics">Analytics</a>
                  </li>
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-par-reseaux-bureaux-campagne">Bureaux campagne</a>
                  </li>
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-par-reseaux-crise">Crise</a>
                  </li>
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-par-reseaux-design">Design</a>
                  </li>
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-par-reseaux-edito">Édito</a>
                  </li>
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-par-reseaux-marque">Marque</a>
                  </li>
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-par-reseaux-numerique">Numérique</a>
                  </li>
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-par-reseaux-prefectures">Préfectures</a>
                  </li>
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-par-reseaux-partenariats">Partenariats</a>
                  </li>
                  <li>
                    <a class="fr-nav__link" href="#" target="_self" id="nav-link-par-reseaux-veille">Veille</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "NavigationDsfr",
};
</script>
