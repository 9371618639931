<template>
  <footer class="fr-footer" role="contentinfo" id="footer">
    <div class="fr-container">
      <div class="fr-footer__body">
        <div class="fr-footer__brand fr-enlarge-link">
          <a id="fr-logo-link-gouvernement" href="/" :title="`Accueil | ${websiteName}`">
            <p class="fr-logo">Gouvernement</p>
          </a>
        </div>
        <div class="fr-footer__content">
          <p class="fr-footer__content-desc">
            Ce site est géré par le Service d’Information du Gouvernement (SIG).
          </p>
          <ul class="fr-footer__content-list">
            <li class="fr-footer__content-item" v-for="(link, index) in externalLinks" :key="index">
              <a
                  :id="link.id"
                  class="fr-footer__content-link"
                  target="_blank"
                  rel="noopener external"
                  :title="`${link.title} - nouvelle fenêtre`"
                  :href="link.href"
              >
                {{ link.text }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="fr-footer__bottom">
        <ul class="fr-footer__bottom-list">
          <li class="fr-footer__bottom-item" v-for="(link, index) in internalLinks" :key="index">
            <!-- Use native <a> tag if the link id is "footer__bottom-link-cookies" to enable tarteaucitron events -->
            <a
                v-if="link.id === 'footer__bottom-link-cookies'"
                :id="link.id"
                :href="link.to"
                class="fr-footer__bottom-link"
                :title="link.title"
            >
              {{ link.text }}
            </a>
            <!-- Otherwise, use <router-link> -->
            <router-link
                v-else
                :id="link.id"
                :to="link.to"
                class="fr-footer__bottom-link"
                target="_self"
                :title="link.title"
            >
              {{ link.text }}
            </router-link>
          </li>
          <li>

          </li>
        </ul>
        <div class="fr-footer__bottom-copy">
          <p>
            Sauf mention explicite de propriété intellectuelle détenue par des tiers, les contenus de ce site sont proposés sous
            <a href="https://github.com/etalab/licence-ouverte/blob/master/LO.md" target="_blank" rel="noopener external" id="footer__link-etalab" title="Licence etalab - nouvelle fenêtre">
              licence etalab-2.0
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { WebsiteName } from '@/utils';

export default {
  name: "FooterDsfr",
  data() {
    return {
      websiteName: WebsiteName,
      externalLinks: [
        {
          id: "footer__content-link-legifrance",
          href: "https://legifrance.gouv.fr",
          title: "legifrance.gouv.fr",
          text: "legifrance.gouv.fr"
        },
        {id: "fr-link-gouvernement", href: "https://www.info.gouv.fr", title: "info.gouv.fr", text: "info.gouv.fr"},
        {
          id: "footer__content-link-service-public",
          href: "https://service-public.fr",
          title: "service-public.fr",
          text: "service-public.fr"
        },
        {id: "footer__content-link-data-gouv", href: "https://data.gouv.fr", title: "data.gouv.fr", text: "data.gouv.fr"},
      ],
      internalLinks: [
        {
          id: "footer__bottom-link-accessibility",
          to: {name: "accessibilite"},
          title: "Accessibilité",
          text: "Accessibilité : partiellement conforme"
        },
        {
          id: "footer__bottom-link-legal-information",
          to: {name: "mentions-legales"},
          title: "Mentions légales",
          text: "Mentions légales"
        },
        {
          id: "footer__bottom-link-personal-data",
          to: {name: "donnees-personnelles"},
          title: "Données personnelles",
          text: "Données personnelles"
        },
        {
          id: "footer__bottom-link-cookies",
          to: "#tarteaucitron",
          title: "Gestion des cookies",
          text: "Gestion des cookies"
        },
        {
          id: "footer__bottom-link-sitemap",
          to: {name: "plan-du-site"},
          title: "Plan du site",
          text: "Plan du site"
        },
      ],
    };
  },
};
</script>
