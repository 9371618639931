<template>
  <div>
    <div id="app">
      <app-skiplinks></app-skiplinks>
      <app-header></app-header>
      <main id="content" role="main" tabindex="-1">
        <router-view />
      </main>
      <app-footer></app-footer>
    </div>
  </div>
</template>

<script>
import SkipLinks from './components/SkipLinks.vue';
import HeaderDsfr from './components/Header.vue';
import FooterDsfr from './components/Footer.vue';
import {WebsiteName} from "@/utils";

export default {
  components: {
    "app-skiplinks": SkipLinks,
    "app-header": HeaderDsfr,
    "app-footer": FooterDsfr
  },
  watch: {
    $route(to) {
      document.title = to.meta?.title ? `${to.meta.title} | ${WebsiteName}` : WebsiteName
    },
  },
}
</script>
<style>
@import 'styles/global.scss';
</style>
