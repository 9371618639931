export default {
    privacyUrl: `${process.env.VUE_APP_PREFIX_PATH}/donnees-personnelles` /* Privacy policy url */,
    bodyPosition:
        'top' /* or top to bring it as first element for accessibility */,
    hashtag: '#tarteaucitron' /* Open the panel with this hashtag */,
    cookieName: 'tarteaucitron' /* Cookie name */,
    orientation: 'bottom' /* Banner position (top - bottom) */,
    groupServices: false /* Group services by category */,
    showDetailsOnClick: false /* Click to expand the description */,
    serviceDefaultState: 'wait' /* Default state (true - wait - false) */,
    showAlertSmall: false /* Show the small banner on bottom right */,
    cookieslist: false /* Show the cookie list */,
    closePopup: false /* Show a close X on the banner */,
    showIcon: false /* Show cookie icon to manage cookies */,
    // "iconSrc": "", /* Optionnal: URL or base64 encoded image */
    iconPosition:
        'BottomRight' /* BottomRight, BottomLeft, TopRight and TopLeft */,
    adblocker: false /* Show a Warning if an adblocker is detected */,
    DenyAllCta: true /* Show the deny all button */,
    AcceptAllCta: true /* Show the accept all button when highPrivacy on */,
    highPrivacy: true /* HIGHLY RECOMMANDED Disable auto consent */,
    handleBrowserDNTRequest: false /* If Do Not Track == 1, disallow all */,
    removeCredit: true /* Remove credit link */,
    moreInfoLink: true /* Show more info link */,
    useExternalCss: true /* If false, the tarteaucitron.css file will be loaded */,
    useExternalJs: true /* If false, the tarteaucitron.js file will be loaded */,
    // "cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */
    readmoreLink: `${process.env.VUE_APP_PREFIX_PATH}/mentions-legales` /* Change the default readmore link */,
    mandatory: false /* Show a message about mandatory cookies */,
    mandatoryCta: true /* Show the disabled accept button when mandatory on */,
    // "customCloserId": "" /* Optional a11y: Custom element ID used to open the panel */
}
