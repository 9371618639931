const link = `${process.env.VUE_APP_PREFIX_PATH}/donnees-personnelles`
export default {
        middleBarHead: '☝ 🍪',
        adblock:
            'Bonjour! Ce site joue la transparence et vous donne le choix des services tiers à activer.',
        adblock_call:
            'Merci de désactiver votre adblocker pour commencer la personnalisation.',
        reload: 'Recharger la page',
        alertBigScroll: 'En continuant de défiler,',
        alertBigClick: 'Ce site utilise des cookies afin de',
        alertBig:
            ' vous proposer des vidéos, des boutons de partage, des messages de communication personnalisés, des remontées de contenus de plateformes sociales et des contenus animés et interactifs.',
        alertBigPrivacy: {
            title: 'À propos des cookies sur l\'Observatoire des priorités du Gouvernement',
            content: `Bienvenue ! Ce site utilise des cookies et d'autres technologies de suivi pour améliorer votre expérience de navigation. Nous conservons votre choix pendant 6 mois. Vous pouvez le modifier en vous rendant sur la page <a href="${link}" class="fr-link fr-link--sm" id="lien-popin-consentement">Données personnelles et cookies</a>.`,
        },
        alertSmall: 'Gestion des services',
        acceptAll: 'Tout accepter',
        personalize: 'Personnaliser',
        close: 'Fermer',
        privacyUrl: 'Données personnelles et cookies',
        all: '',
        info: 'Protection de votre vie privée',
        disclaimer: "Préférences pour tous les services.",
        allow: 'Autoriser',
        deny: 'Interdire',
        noCookie: 'Ce service ne dépose aucun cookie.',
        useCookie: 'Ce service peut déposer',
        useCookieCurrent: 'Ce service a déposé',
        useNoCookie: "Ce service n'a déposé aucun cookie.",
        more: 'En savoir plus',
        source: 'Voir le site officiel',
        credit: 'Gestion des cookies par tarteaucitron.js',
        noServices:
            "Ce site n'utilise aucun cookie nécessitant votre consentement.",
        toggleInfoBox:
            'Afficher/masquer les informations sur le stockage des cookies',
        title: "Panneau de gestion des cookies",
        cookieDetail: 'Détail des cookies',
        ourSite: 'sur notre site',
        modalWindow: '(fenêtre modale)',
        newWindow: 'nouvelle fenêtre',
        allowAll: 'Tout accepter',
        allowFeature:
            'Autorisez le dépôt de cookies pour accéder à cette fonctionnalité',
        allowService: 'Autorisez le dépôt de cookies pour accéder au service',
        denyAll: 'Tout refuser',
        icon: 'Cookies',
        fallback: 'est désactivé.',
        allowed: 'autorisé',
        disallowed: 'interdit',
        ads: {
            title: "Campagnes d'informations gouvernementales",
            details:
                'Les campagnes d’informations gouvernementales permettent le partage d’informations utiles diffusées dans le cadre de grandes campagnes d’information, ou en cas de crise.',
        },
        analytic: {
            title: "Mesure d'audience",
            details:
                'La mesure de l’audience du site contribue à l’amélioration continue de son ergonomie, de sa navigation et de ses contenus.',
        },
        social: {
            title: 'Réseaux sociaux',
            details:
                'Les réseaux sociaux permettent d’enrichir l’expérience utilisateur sur le site et aident à sa promotion via les partages.',
        },
        video: {
            title: 'Vidéos',
            details:
                "Les services de partage de vidéo permettent d'enrichir le site de contenus multimédia tout en conservant une vitesse de chargement optimale, et contribuent à son référencement.",
        },
        comment: {
            title: 'Commentaires',
            details:
                'Les gestionnaires de commentaires facilitent le dépôt de vos commentaires et luttent contre le spam.',
        },
        support: {
            title: 'Support',
            details:
                "Les services de support vous permettent d'entrer en contact avec l'équipe du site et d'aider à son amélioration.",
        },
        api: {
            title: 'Contenus interactifs',
            details:
                'Les contenus interactifs permettent d’enrichir l’expérience utilisateur et de contribuer à une meilleure compréhension des informations disponibles sur le site.',
        },
        other: {
            title: 'Autre',
            details: 'Services visant à afficher du contenu web.',
        },
        mandatoryTitle: 'Cookies obligatoires',
        mandatoryText:
            'Ce site utilise des cookies nécessaires à son bon fonctionnement. Ils ne peuvent pas être désactivés.',
    };
