const state = {
    isMainScreen: true,
    isCompared: false,
};

const getters = {
    isMainScreen: (state) => state.isMainScreen,
    isCompared:(state) => state.isCompared,
};

const actions = {
    setMainScreen({ commit }, isMainScreen) {
        commit('SET_IS_MAIN_SCREEN', isMainScreen);
    },
    setIsCompared({commit}, isCompared) {
        commit('SET_IS_COMPARED', isCompared);
    },
}

const mutations = {
    SET_IS_COMPARED(state, isCompared){
        state.isCompared = isCompared;
    },
    SET_IS_MAIN_SCREEN(state, isMainScreen) {
        state.isMainScreen = isMainScreen;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
